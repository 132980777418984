import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


// var dataLayer = [];
// document.write('<script async src="https://www.googletagmanager.com/gtag/js?id=G-JX3S6DX3G0"></script>');
//
// setTimeout(() => {
//     window.dataLayer = window.dataLayer || [];
//     function gtag(){dataLayer.push(arguments);}
//     gtag('js', new Date());
//
//     gtag('config', 'G-JX3S6DX3G0');
// }, 1000);

createApp(App).use(router).mount('#app')
