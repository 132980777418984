<template>
  <div class="page home">
    <div id="slide-container">
      <div id="slide-element-2" class="slide">
        <div class="main">
          <h5>
            Delivering breakthrough science to inhibit the spread of airborne
            respiratory viruses
          </h5>
          <div class="h-bottom">
            <!-- <h3>I want...</h3>
              <h3>...to feel free</h3>
              <img src="../assets/img/phoxgen-well.svg" alt="phoxgen-phoxwell"> -->
            <img
              src="../assets/img/pHOXWELL–pHOXGEN-white-out_strapline.svg"
              alt="phoxgen-phoxwell"
            />
          </div>
        </div>
      </div>
      <div id="slide-first-element" class="slide">
        <div class="main">
          <h5>
            Delivering breakthrough science to inhibit the spread of airborne
            respiratory viruses
          </h5>
          <div class="h-bottom">
            <img
              src="../assets/img/pHOXWELL–pHOXGEN-white-out_strapline.svg"
              alt="phoxgen-phoxwell"
            />
          </div>
        </div>
      </div>
      <div id="slide-element-3" class="slide">
        <div class="main">
          <h5>
            Delivering breakthrough science to inhibit the spread of airborne
            respiratory viruses
          </h5>
          <div class="h-bottom">
            <img
              src="../assets/img/pHOXWELL–pHOXGEN-white-out_strapline.svg"
              alt="phoxgen-phoxwell"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="group-text">
        <p>
          pHOXBIO is a privately held biopharmaceutical company developing
          pHOXWELL <br />
          and pHOXGEN, two unique solutions targeting prophylaxis of airborne
          respiratory <br />viruses (ARVs) such as coronaviruses and influenza
          viruses that cause some of the <br />
          world's deadliest communicable diseases.
        </p>

        <p>
          pHOXWELL and pHOXGEN are self-administered prophylactic nasal sprays
          designed to offer<br />
          a mutation agnostic mechanism of action that provides a robust defence
          to inhibit<br />
          the infection processes of SARS-CoV-2. They should also be effective
          against many other<br />
          important airborne respiratory viruses.
        </p>

        <p>
          pHOXBIO products represent a scientific breakthrough with the
          potential <br />to impact public health and strengthen pandemic
          preparedness by <br />
          addressing substantial gaps in current control measures.
        </p>
      </div>
    </div>
    <div class="learn-more">
      <div class="container">
        <h1>Learn More</h1>
        <div class="learning">
          <div class="el">
            <a href="https://phoxbio.com/#/burden-of-arps">
              <img src="../assets/img/icons/arps.svg" alt="ARPs" />
              <p>
                Easily spread, difficult to stop and presenting persistent
                global health and economic threats
              </p>
            </a>
          </div>
          <div class="el">
            <a href="https://phoxbio.com/#/substantial-gaps">
              <img src="../assets/img/icons/control.svg" alt="Control Gaps" />
              <p>
                Provide critical layer of defense for pandemic management and
                preparedness
              </p>
            </a>
          </div>
          <div class="el">
            <a href="https://phoxbio.com/#/solution-breakthrough">
              <img
                src="../assets/img/icons/our-solutions.svg"
                alt="Our Solutions"
              />
              <p>
                Effective breakthrough science to inhibit the infection process
                of ARVs
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};

var current = 0,
  slides = document.getElementsByClassName("slide");

if (slides.length) {
  setInterval(function() {
    if (slides) {
      for (var i = 0; i < slides.length; i++) {
        slides[i].style.opacity = 0;
      }
      current = current != slides.length - 1 ? current + 1 : 0;
      slides[current].style.opacity = 1;
    }
  }, 4000);
}
</script>

<style scoped lang="scss">
.main {
  height: 528px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 0 100px;
  padding-top: 49px;
  padding-bottom: 55px;
  position: relative;
}
h5 {
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  margin: 0;
  max-width: 565px;
  text-align: left;
}
.h-bottom {
  position: absolute;
  bottom: 55px;
  right: 178px;
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 31px;
    color: #ffffff;
    text-align: left;
    margin: 0;
    margin-top: 9px;
    &:first-child {
      margin-left: -25px;
    }
  }
  img {
    margin-top: 7px;
    width: auto;
    height: 59px;
  }
}
.group-text {
  margin: 71px 10px;
  p {
    margin-bottom: 33px;
    padding: 0 20px;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }
}

.learn-more {
  background: #9e9f9e;
  padding: 50px;
  padding-bottom: 60px;
  .container {
    max-width: 814px;
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 150%;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    color: #ffffff;
    margin-bottom: 10px;
  }
  p.sub {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    margin: 0;
  }
  .learning {
    margin-top: 60px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .el a {
      text-decoration: none;
    }
    .el img {
      border-radius: 9px;
      transition: all 0.2s ease-in-out;
      width: 100px;
      height: 100px;
      &:hover {
        background: #b3b3b3;
      }
    }
    p {
      margin-top: 40px;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
      text-align: center;
      color: #ffffff;
      max-width: 218px;
    }
  }
}

@media screen and (max-width: 768px) {
  .main {
    padding: 23px 24px;
    padding-top: 49px;

    .h-bottom {
      right: 24px;
      h3 {
        font-size: 24px;
        line-height: 23px;
        &:first-child {
          margin-left: 0;
        }
        &:nth-child(2) {
          margin-left: 17px;
        }
      }
      img {
        margin-top: 11px;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .main {
    padding: 23px 24px;
    h5 {
      font-size: 24px;
      line-height: 150%;
      text-align: center;
    }
  }
  .h-bottom {
    bottom: 24px;
  }
  .group-text {
    margin: 44px 0px;
    br {
      display: none;
    }
  }
  .group-text p {
    font-size: 20px;
    line-height: 150%;
    font-weight: 200;
    padding: 0 24px;
    margin-bottom: 0;
  }
  .learn-more {
    padding: 50px 24px;
    h1 {
      font-size: 32px;
      line-height: 150%;
    }
    .learning {
      flex-direction: column;
      align-items: center;
      .el {
        img {
          width: 130px;
          height: 130px;
        }
      }
      p {
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 47px;
        line-height: 150%;
        max-width: unset;
      }
    }
  }
}

@media screen and (max-width: 468px) {
  .main .h-bottom {
    left: 24px;
  }

  .h-bottom img {
    width: 100%;
    height: auto;
  }
}

#slide-container {
  position: relative;

  .slide {
    transition: opacity 0.5s ease-in;
  }

  .slide + .slide {
    opacity: 0;
  }
}
/* First element to be in block mode for responsiveness */
#slide-first-element {
  display: block; /* to get the dimensions set */
  width: 100%;
  height: auto;
}
/* Other element to be in absolute position */
#slide-element-2,
#slide-element-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#slide-first-element {
  .main {
    background-image: url(../assets/img/home.jpg);
  }
}
#slide-element-2 {
  .main {
    background-image: url(../assets/img/slider/focus.jpg);
  }
}
#slide-element-3 {
  .main {
    background-image: url(../assets/img/slider/slide_2.jpg);
  }
}
// @keyframes fade {
//   0%   { opacity: 0; }
//   11.11%   { opacity: 1; }
//   33.33%  { opacity: 1; }
//   44.44%  { opacity: 0; }
//   100% { opacity: 0; }
// }
</style>
