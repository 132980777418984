<template>
	<div class="page borden-arps">
		<div class="main">
			<h5>Burden of Airborne Respiratory Pathogens</h5>
			<p>ARPs cause ~5 million preventable deaths each year ... even without the COVID-19 pandemic. <sup>Ref
				1</sup></p>
			<div class="options">
				<ul>
					<li><p>Spread directly contributes to respiratory infection — the deadliest communicable disease</p>
					</li>
					<li><p>Respiratory diseases are a top 10 cause of disability <sup>Ref 1</sup></p></li>
				</ul>
				<p class="s">Preventable respiratory diseases are a major source of morbidity and mortality in the
					developing world, consistently ranking among a top-five cause of death. <sup>Ref 1</sup> Amongst the
					most important of airborne respiratory pathogens are viruses</p>
			</div>
		</div>

		<div class="imgs">
			<div class="global-causes">
				<sup>Ref 1</sup> <img alt="Global Causes of Death (2019)" src="../assets/img/global-causes-death.svg">
				<img alt="Global Causes of Death (2019)" class="global-causes mobile" src="../assets/img/gcd-mobile.svg">
				<img alt="Global Causes of Death (2019)" class="global-causes mobile" src="../assets/img/gcd-sub.svg">
			</div>
		</div>
		<div class="imgs arps">
			<div class="left">
				<h5>ARPs ARE EASILY SPREAD, DIFFICULT<br> TO STOP </h5>
				<p>ARPs are easily transmitted through sneezing, coughing, talking or any activity that results in the
					generation of aerosolised particles.</p>
			</div>
			<div class="economic-health">
				<sup>Ref 3</sup> <img alt="ARPS present major global" src="../assets/img/arps.svg">
			</div>
		</div>

		<div class="grey-bg">

			<div class="imgs">
				<h2 class="section-title">MAJOR ECONOMIC AND HEALTH CARE BURDENS</h2>
			</div>
			<div class="imgs he">

				<div class="forty-left">
					<p>Coronaviruses</p>
					<p>Virulent, unpredictable and widespread threats to global public health</p>
				</div>
				<div class="sixty-right">
					<ul class="big">
						<li>Novel forms are highly virulent, emerge suddenly and unpredictably, and present significant
							health threats
						</li>
						<li>SARS-CoV-2, which causes COVID-19, became a top 10 cause of death worldwide within one year
							of its identification
						</li>
						<li>Common forms can lead to poor outcomes for vulnerable populations</li>
					</ul>
				</div>
			</div>

			<div class="imgs he">
				<div class="forty-left">
					<p>Inﬂuenza viruses</p>
					<p>Seasonal threats with major impact on global health</p>
				</div>
				<div class="sixty-right">
					<ul class="big">
						<li>Causes 1 billion infections, 3-5m severe infections, and up to 650K influenza-related respiratory
							deaths worldwide each year <sup>Ref 3, Ref 4</sup></li>
						<li>Up to 54% of healthcare workers have reported developing flu during outbreaks in healthcare
							facilities <sup>Ref 5</sup></li>
						<li>Highest mortality rates are seen in Sub-Saharan Africa among those aged &lt;65 and in South
							East Asia among those aged >65 <sup>Ref 4</sup></li>
					</ul>
				</div>
			</div>

			<div class="imgs he">
				<div class="forty-left">
					<p>Rhinoviruses</p>
					<p>Most common viral infectious agents in humans</p>
				</div>
				<div class="sixty-right">
					<ul class="big" style="margin-top: 20px">
						<li>Predominant cause of common cold and leading cause of doctor visits and missed school and
							work days <sup>Ref 6, Ref 7</sup></li>
						<li>Infants, elderly and immunocompromised are most at risk of adverse outcomes due to
							rhinovirus <sup>Ref 6, Ref 7</sup></li>
					</ul>
				</div>
			</div>

		</div>

		<div class="imgs spread">
			<h5>SUDDEN, UNPREDICTABLE EMERGENCE OF COVID-19<br> QUICKLY CAUSED PROFOUND SOCIETAL DISRUPTION WORLDWIDE
			</h5>
			<div class="gro">
				<div class="el">
					<img alt="" src="../assets/img/causes/1.svg">
					<div class="te">
						<b>Devastating Death Toll</b>
						<p>More than 5,880,000 deaths and 421 million cases in under 2 years<br><sup>ref 9</sup></p>
					</div>
				</div>
				<div class="el">
					<img alt="" src="../assets/img/causes/2.svg">
					<div class="te">
						<b>Front-line Worker Risk</b>
						<p>Front-line healthcare workers caring for COVID-19 patients have ~12x greater risk of
							infection vs. public — despite PPE use <sup>Ref 10</sup></p>
					</div>
				</div>

				<div class="el">
					<img alt="" src="../assets/img/causes/3.svg">
					<div class="te">
						<b>Unemployment</b>
						<p>Equivalent of 255 million <sup>Ref 11</sup> full-time lost jobs in 2020 — 9% of global
							working hours (4x greater than 2009 ﬁnancial crisis) <sup>Ref 12</sup></p>
					</div>
				</div>
				<div class="el">
					<img alt="" src="../assets/img/causes/4.svg">
					<div class="te">
						<b>GDP</b>
						<p>OECD projects will take at least two years to recover from negative impacts on 2020 <br>GDP
							<sup>Ref 13</sup></p>
					</div>
				</div>
				<div class="el">
					<img alt="" src="../assets/img/causes/5.svg">
					<div class="te">
						<b>Commerce</b>
						<p>Loss of international travel demand could lead to ~$1.2T in lost export <sup>Ref 14</sup>
							revenues from tourism and 120M direct tourism jobs <sup>Ref 14</sup></p>
					</div>
				</div>
				<div class="el">
					<img alt="" src="../assets/img/causes/6.svg">
					<div class="te">
						<b>Education</b>
						<p>~1.7 billion learners affected by pandemic <sup>Ref 11</sup> - related school closures,
							impacting 98% of world’s students <sup>Ref 15</sup></p>
					</div>
				</div>
				<div class="el">
					<img alt="" src="../assets/img/causes/omicron.svg">
					<div class="te">
						<b>Omicron Variant</b>
						<p>Imperial College London estimates that the risk of reinfection with the Omicron variant is 5.4 times greater than that of the Delta variant <sup>Ref 18</sup></p>
					</div>
				</div>
				<div class="el">
					<img alt="" src="../assets/img/causes/booster_impact.svg">
					<div class="te">
						<b>Booster Impact</b>
						<p>The booster vaccine provided over 90% protection from symptomatic infection in adults 50 years and over <sup>Ref 21</sup></p>
					</div>
				</div>
			</div>
		</div>
		<div class="references">
			<h3>References</h3>

			<!--<p>  1. Global Health Estimates 2016. Deaths by Cause, Age, Sex by Country and by Region. 2000-2016. World Health Organization 2018.-->
			<!--<br> 2. World Health Organization. Global Health Estimates. Top 10 causes of death. Accessed 9 December 2020.-->
			<!--<br> 3. Influenza (seasonal) fact sheet. WHO. Accessed 1 June 2020.-->
			<!--<br> 4. J Glob Health. 2019 Dec; 9(2): 020421.-->
			<!--<br> 5. Trish M. Universal Influenza Vaccination Among Healthcare Personnel: Yes We Should. Open Forum Infectious Diseases 2019; 6(4).-->
			<!--<br> 6. Common cold overview. WebMD. Accessed 1 June 2020.-->
			<!--<br> 7. Human Rhinoviruses. Clinical Microbiology Reviews. 26 (1): 135–62. 2013.-->
			<!--<br> 8. <a href="https://coronavirus.jhu.edu/map.html">John Hopkins University Coronavirus Resource Centre</a>-->
			<!--<br> 9. Massachusetts General Hospital. Marquedant K. Study Reveals the Risk of COVID-19 Infection Among Health Care Workers. Published 5 May 2020.-->
			<!--<br> 10. How COVID-19 is changing the world: a statistical perspective. Committee for the Coordination of Statistical Activities (CCSA). Published May 2020.-->
			<!--<br> 11. International Labour Organization. ILO Monitor: COVID-19 and the world of work. Seventh edition Updated estimates and analysis. 25 Jan 2021.-->
			<!--<br> 12. OECD Economic Outlook — June 2020 Update. OECD.-->
			<!--<br> 13. UNWTO World Tourism Barometer May 2020. United Nations World Tourism Organization. Published 7 May 2020.-->
			<!--<br> 14. COVID-19 Educational Disruption and Response. UNESCO. Accessed 1 June 2020.-->
			<!--<br> 15. Human coronavirus types. Centers for Disease Control. Published 15 February 2020.-->
			<!--<br> 16. Health Aff. (Millwood) 35, 2124–2132 (2016).-->
			<!--<br> 17. Health Insurance Status and Risk Factors for Poor Outcomes With COVID-19 Among U.S. Health Care Workers. Annals Int Med. Published 28 April 2020.-->
			<!--<br> Annals Int Med. (Himmelstein) 173;5. Published 1 Sept 2020.-->
			<!--</p>-->


			<p>1. Global Health Estimates 2016. Deaths by Cause, Age, Sex by Country and by Region. 2000-2016. World Health Organization 2018.
			<br>2. World Health Organization. Global Health Estimates. Top 10 causes of death. Accessed 9 December 2020.
			<br>3. Influenza (seasonal) fact sheet. WHO. Accessed 1 June 2020.
			<br>4. J Glob Health. 2019 Dec; 9(2): 020421.
			<br>5. World Health Organisation, Seasonal Influenza and Communicable Diseases
			<br>6. Trish M. Universal Influenza Vaccination Among Healthcare Personnel: Yes We Should. Open Forum Infectious Diseases 2019; 6(4).
			<br>7. Common cold overview. WebMD. Accessed 1 June 2020.
			<br>8. Human Rhinoviruses. Clinical Microbiology Reviews. 26 (1): 135–62. 2013.
			<br>9. World O Meter Coronavirus Statistics <a href="https://www.worldometers.info/coronavirus/">https://www.worldometers.info/coronavirus/</a>
			<br>11. How COVID-19 is changing the world: a statistical perspective. Committee for the Coordination of Statistical Activities (CCSA). Published May 2020.
			<br>12. International Labour Organization. ILO Monitor: COVID-19 and the world of work. Seventh edition Updated estimates and analysis. 25 Jan 2021.
			<br>13. OECD Economic Outlook — June 2020 Update. OECD. <br>14. UNWTO World Tourism Barometer May 2020. United Nations World Tourism Organization. Published 7 May 2020.
			<br>15. COVID-19 Educational Disruption and Response. UNESCO. Accessed 1 June 2020. <br>16. Human coronavirus types. Centers for Disease Control. Published 15 February 2020.
			<br>17. Health Aff. (Millwood) 35, 2124–2132 (2016).
			<br>18. Health Insurance Status and Risk Factors for Poor Outcomes With COVID-19 Among U.S. Health Care Workers. Annals Int Med. Published 28 April 2020. Annals Int Med. (Himmelstein) 173;5. Published 1 Sept 2020.
			<br>19. Omicron Variant Statistical Representatives per Country, <a href="https://www.statista.com/statistics/1279100/number-omicron-variant-worldwide-by-country/">https://www.statista.com/statistics/1279100/number-omicron-variant-worldwide-by-country/</a>
			<br>20. Office for National Statistics, Booster Impact <a href="https://www.ons.gov.uk/peoplepopulationandcommunity/healthandsocialcare/conditionsanddiseases/articles/coronaviruscovid19latestinsights/vaccines">https://www.ons.gov.uk/peoplepopulationandcommunity/healthandsocialcare/conditionsanddiseases/articles/coronaviruscovid19latestinsights/vaccines</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>

.grey-bg {
	background-color: #b2b2b2;
	display: grid;
	padding: 47px 0;
	row-gap: 37px;

	.section-title {
		color: #FFFFFF;
		font-size: 28px;
		font-weight: 500;
		line-height: 27px;
		margin: 0;
		text-align: left;
	}

	.he {
		align-items: center;
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}

.forty-left {
	display: grid;
	grid-template-columns: 2fr 3fr;
	height: 100px;
	max-width: 520px;
	text-align: left;

	p {
		align-items: center;
		background: #1D71B8;
		color: #FFFFFF;
		display: flex;
		font-size: 20px;
		font-weight: 500;
		line-height: 120%;
		margin: 0;
		padding: 17px 20px;
		position: relative;

		&:last-child {
			background: #E30613;

			&:after {
				background: url(../assets/img/arrow-tip.svg);
				background-repeat: no-repeat;
				background-size: contain;
				content: "";
				height: 106px;
				position: absolute;
				right: -51px;
				width: 52px;
			}
		}

		img {
			height: 100%;
			margin: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: auto;
		}
	}
}


ul.big {
	list-style-type: none;
	margin: 0;
	margin-top: 6px;
	padding: 0 0 0 60px;
	text-align: left;

	li {
		color: #222222;
		font-size: 16px;
		font-weight: normal;
		line-height: 120%;
		margin-bottom: 8px;
		padding-left: 35px;
		position: relative;
		// sup {
		//     position: absolute;
		//     margin-top: -3px;
		//     margin-left: 3px;
		// }

		&:before {
			background: #FFFFFF;
			content: '';
			height: 18px;
			left: 0;
			position: absolute;
			top: 4px;
			width: 18px;
		}
	}
}

.economic-health {
	display: flex;
	flex-direction: column;
}


.main {
	background-color: #0C0824;
	padding: 0 100px;
	padding-bottom: 55px;
	padding-top: 64px;
	position: relative;

	h5 {
		color: #FFFFFF;
		font-size: 38px;
		font-style: normal;
		font-weight: 300;
		line-height: 46px;
		margin: 0;
		max-width: 505px;
		padding-bottom: 20px;
		text-align: left;
	}

	p {
		color: #FFFFFE;
		font-size: 17px;
		font-style: normal;
		font-weight: 300;
		line-height: 20px;
		margin-bottom: 20px;
		max-width: 471px;
		text-align: left;
	}

	.options {
		padding-left: 38px;

		ul {
			list-style-type: none;
			margin-bottom: 28px;
			padding: 0;

			li {
				padding-left: 20px;
				position: relative;

				&:before {
					background: #D62631;
					content: '';
					height: 8px;
					left: 0;
					position: absolute;
					top: 3px;
					width: 8px;
				}
			}
		}
	}
}

.imgs.arps {
	display: flex;
	justify-content: space-between;

	.left {
		text-align: left;
		width: 403px;
	}

	.sixty-right {
		width: 55%
	}

	img {
		margin-top: 10px;
		width: 690px;
	}

	h5 {
		color: #706F6F;
		font-size: 28px;
		font-weight: normal;
		line-height: 34px;
		margin-top: 10px;
	}

	p {
		color: #1C1339;
		font-size: 20px;
		font-weight: normal;
		line-height: 170%;
	}
}

.imgs.spread {
	margin: 0;
	padding: 64px 100px;

	h5 {
		color: #493E62;
		font-size: 28px;
		font-style: normal;
		font-weight: bold;
		line-height: 27px;
		margin: 0;
		margin-bottom: 54px;
		text-align: center;
	}

	.gro {
		column-gap: 24px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		row-gap: 56px;
		text-align: left;

		.el {
			display: flex;
			// sup {
			//   position: absolute;
			//   margin-top: -3px;
			//   margin-left: 3px;
			// }
			img {
				height: 145px;
				margin: 0;
				margin-right: 16px;
				max-width: 145px;
				width: auto;
			}

			b {
				color: #1D71B8;
				color: #1D71B8;
				display: block;
				font-size: 17px;
				font-weight: 500;
				line-height: 17px;
				margin: 0;
				margin-bottom: 8px;
			}

			p {
				color: #b2b2b2;
				font-size: 14px;
				font-weight: 500;
				line-height: 149%;
				margin: 0;
			}
		}
	}
}

.imgs {
	margin: 0 100px;

	.global-causes {
		display: flex;
		flex-direction: column;
		margin: 60px 0;
		text-align: left;
		width: 100%;

		img {
			margin: unset;
			width: unset;
		}
	}

	img {
		margin: 60px 0;
		width: 100%;
	}
}

.img-grey {
	background: #b2b2b2;
	padding: 0 100px;

	img {
		width: 100%;
	}
}

.references {
	background: #CCD5E3;
	padding: 0 100px;
	padding-bottom: 58px;
	padding-top: 50px;

	h3 {
		color: #0c0824;
		font-size: 32px;
		font-style: normal;
		font-weight: 300;
		line-height: 38px;
		margin: 0;
		margin-bottom: 17px;
		text-align: left;
	}

	p {
		color: #0C0824;
		font-size: 12px;
		font-style: normal;
		font-weight: 300;
		line-height: 21px;
		text-align: left;
	}
}

.imgs {
	.global-causes {
		&.mobile {
			display: none;
		}
	}
}

@media screen and (max-width: 1200px) {
	.forty-left p {
		font-size: 18px;
	}
	.forty-left p:last-child:after {
		height: 100px;
	}
	.imgs.spread .gro {
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (max-width: 1100px) {
	.imgs.arps {
		display: block;
	}
	.grey-bg {
		row-gap: 0;
	}
	.grey-bg .he {
		grid-template-columns: 1fr;
	}
	.forty-left {
		grid-template-columns: 1fr 1fr;
		margin: 20px 0;
		max-width: unset;
		width: 100%;
	}
	.forty-left p:last-child:after {
		display: none;
	}
	ul.big li:before {
		display: none;
	}
	ul.big {
		padding: 0;
	}
	.sixty-right {
		margin-top: -5px;
	}
	ul.big li {
		border-bottom: 1px solid;
		margin: 0;
		padding: 0;
		padding: 7px 0;

		&:last-child {
			border: none;
		}
	}
}

@media screen and (max-width: 900px) {
	.main {
		padding: 53px 40px;
	}
	.imgs {
		margin: 0 40px;
	}
	.references {
		padding: 34px 40px;
		padding-bottom: 50px;
	}
	.imgs.spread .gro {
		grid-template-columns: 1fr;
	}
	.imgs.spread {
		padding: 47px 40px;
	}
}

@media screen and (max-width: 768px) {
	.imgs.spread {
		h5 {
			font-size: 22px;
			font-style: normal;
			font-weight: bold;
			line-height: 21px;
			text-align: left;
		}
	}
	.imgs.arps {
		display: block;
		margin-top: 40px;

		h5 {
			margin-top: 0;
		}

		img {
			width: 100%;
		}
	}
	.imgs {
		.global-causes {
			display: none;

			&.mobile {
				display: block;
			}
		}
	}
}

@media screen and (max-width: 560px) {
	.imgs.spread {
		padding: 40px 24px;
	}
	.grey-bg .section-title {
		font-size: 22px;
	}
	.forty-left {
		height: unset;
		min-height: 76px;

		p {
			font-size: 13px;
			font-weight: normal;
			font-weight: 500;
			line-height: 120%;
			padding: 10px 11px;

			&:first-child {
				justify-content: center;
			}

		}
	}
	.imgs.arps {
		.left {
			width: auto;

			h5 {
				font-size: 22px;
				font-weight: 500;
				line-height: 34px;
				margin-bottom: 18px;
			}

			br {
				display: none;
			}

			p {
				font-size: 16px;
				font-weight: normal;
				line-height: 178%;
			}
		}
	}
	.main {
		padding: 53px 24px;
	}
	.imgs {
		margin: 0 24px;

		img {
			margin: 20px 0;
		}
	}
	.main {
		padding: 43px 24px;

		h5 {
			font-size: 28px;
			line-height: 34px;
		}

		p {
			line-height: 20px;
		}

		.options {
			padding-left: 14px;

			.s {
				padding-left: 20px;
			}
		}
	}
	.references {
		padding: 30px 24px;
		padding-bottom: 50px;
	}
}

</style>
